import React, { FunctionComponent, useMemo, useState } from 'react'
import Layout from '../components/layout/layout'
import { MarkdownRemarkConnection, Site } from '@/graphql-types'
import classnames from 'classnames'
import { PostItem } from '../components/post-item'
import { graphql } from 'gatsby'
import { Card } from '../components/card'

const Styles = require('../styles/pages/tags.module.styl')

type Props = {
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    site: Site
  }
  location: Location
}

const Tags: FunctionComponent<Props> = ({ location, data: { site, allMarkdownRemark } }) => {
  const tags = useMemo<string[]>(() => {
    let _tags: string[] = []

    allMarkdownRemark.edges.forEach(edge => {
      _tags = _tags.concat(edge.node.frontmatter?.tags ?? [])
    })
    return Array.from(new Set(_tags)).sort()
  }, [allMarkdownRemark.edges])

  const [selectedTag, setSelectedTag] = useState(tags[0])

  const posts = useMemo(() => allMarkdownRemark.edges.filter(edge => edge.node.frontmatter?.tags?.some(tag => tag === selectedTag)), [
    selectedTag,
  ])

  return (
    <Layout>
      <Card cardCls={Styles['card']}>
        <div className={Styles['header']}> Tags </div>
        <div className={Styles['line']} />
        <div className={Styles['tags_wrap']}>
          {tags.map(tag => (
            <span
              onClick={() => setSelectedTag(tag)}
              className={classnames(Styles['tag_item'], { [Styles['active']]: selectedTag === tag })}
              key={tag}
            >
              #{tag}
            </span>
          ))}
        </div>
      </Card>
      {posts.map(post => {
        return (
          post.node && (
            <PostItem
              path={`/posts/post-${new Date(post.node.frontmatter?.date).getTime()}`}
              key={post.node.id}
              node={post.node}
              className={Styles['post_item']}
            />
          )
        )
      })}
    </Layout>
  )
}

export default Tags

export const query = graphql`
  query TagQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
      edges {
        node {
          id
          frontmatter {
            date
            description
            tags
            title
          }
          fields {
            slug
          }
          fileAbsolutePath
          wordCount {
            words
          }
          timeToRead
        }
      }
    }
  }
`
